import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { getRandomInt } from '../../helpers/_utilities';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const { get } = $dom

const rootElement = '.js-information-counter';

const regionsCountElement = '.js-count-regions';
const yearsCountElement = '.js-count-years';
const directionsCountElement = '.js-count-directions';

const triangleTopLeftSelector = '.js-triangle-top-left';
const triangleTopRightSelector = '.js-triangle-top-right';
const triangleBottomLeftSelector = '.js-triangle-bottom-left';
const triangleBottomRightSelector = '.js-triangle-bottom-right';

const infoBlockSelector = '.js-counter-block';

export function informationCounter() {

  const timeline = gsap.timeline({ repeat: -1 })
  
  const counterAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: infoBlockSelector,
      start: 'top 75%',
      end: `+=${get(infoBlockSelector).offsetHeight}`,
      scrub: true,
      markers: false,
    }
  })

  const duration = 1.2,
    transformOrigin = '50% 50%',
    delay = 1;

  const counter = () => {
    return counterAnimation
      .to(regionsCountElement, {
        textContent: getRandomInt(1, 999),
        snap: 'textContent',
      }, 'count')
      .to(yearsCountElement, {
        textContent: getRandomInt(1, 999),
        snap: 'textContent',
      }, 'count')
      .to(directionsCountElement, {
        textContent: getRandomInt(1, 999),
        snap: 'textContent',
      }, 'count')
  }

  const triangleAnimation = () => {
    timeline.to(triangleTopLeftSelector, {
      x: 150,
      rotate: -90,
      transformOrigin,
      duration,
      delay,
    })
    timeline.to(triangleBottomRightSelector, {
      rotate: -90,
      transformOrigin,
      duration,
    }, "<")

    timeline.to(triangleBottomLeftSelector, {
      x: 73,
      y: -70,
      transformOrigin,
      duration,
      delay,
    })
    timeline.to(triangleTopRightSelector, {
      rotate: -90,
      transformOrigin,
      duration,
    }, "<")

    timeline.to(triangleTopLeftSelector, {
      x: 0,
      scale: '-1, 1',
      transformOrigin,
      duration,
      delay,
    })
    timeline.to(triangleBottomRightSelector, {
      rotate: 0,
      transformOrigin,
      duration,
    }, "<")

    timeline.to(triangleTopLeftSelector, {
      rotate: 0,
      scale: '1, 1',
      transformOrigin,
      duration,
      delay,
    })
    timeline.to(triangleBottomLeftSelector, {
      x: 0,
      y: 0,
      transformOrigin,
      duration,
    }, "<")
    timeline.to(triangleTopRightSelector, {
      rotate: 0,
      transformOrigin,
      duration,
    }, "<")
  }

  return new Component({
    name: 'informationCounter',
    requiredTargets: rootElement,
    onCreate() {

    },

    onInit() {
      this.counterAnimation = counter();
      triangleAnimation();
    },

    onDestroy() {
      timeline.kill();
    }
  })
}