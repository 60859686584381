import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import Headroom from "headroom.js";
import { $events } from '../helpers/events';
import debounce from 'lodash.debounce';
import { $style } from '../helpers/style';

const { get, hasClass } = $dom

const rootElement = '.js-header';

const headerCollapseElement = get('.js-header-collapse');

let isCollapseShown = false;

export function stickyHeader() {

  const collapse = this.bootstrap.Collapse.getOrCreateInstance(headerCollapseElement, {
    toggle: false,
  });

  const modalOpenHandler = () => {
    let div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    document.body.append(div);

    let scrollWidth = div.offsetWidth - div.clientWidth;

    div.remove();

    $style.set(rootElement, 'transition', 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out')
    $style.set(get(rootElement), 'padding-right', `${scrollWidth}px`)
  }

  const modalCloseHandler = () => {
    $style.remove(get(rootElement), 'padding-right')
  }

  const collapseHide = target => {
    if (!target.closest('.js-header-collapse')) {
      collapse.hide();
      isCollapseShown = false;
    }
  }
  
  const collapseHandler = () => {
    if (hasClass(get(rootElement), 'headroom--unpinned') && isCollapseShown) {
      collapse.hide();
      isCollapseShown = false;
    }
  }

  const checkCollapse = () => {
    isCollapseShown = true;
  }

  return new Component({
    name: 'stickyHeader',
    requiredTargets: rootElement,
    onCreate() {
      this.headroom = null;
    },
    onInit() {
      const header = get(rootElement);
      this.headroom = new Headroom(header);
      this.headroom.init();

      $events.add('scroll', window, collapseHandler);

      $events.delegate
        .on('click', window, event => collapseHide(event.target))
        .on('shown.bs.collapse', headerCollapseElement, checkCollapse)
        .on('show.bs.modal', window, modalOpenHandler)
        .on('hidden.bs.modal', window, modalCloseHandler)
    },
    onDestroy() {
      if (this.headroom instanceof Headroom) {
        this.headroom.destroy();
        this.headroom = null;
      }

      $events.remove('scroll', window, collapseHandler);
      
      $events.delegate
        .off('click', window, event => collapseHide(event.target))
        .off('shown.bs.collapse', headerCollapseElement, checkCollapse)
        .off('show.bs.modal', window, modalOpenHandler)
        .off('hidden.bs.modal', window, modalCloseHandler)
    }
  })
}