import { gsap } from 'gsap';
import Swiper, { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper';
import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';

const { get, getAll, addClass, removeClass, attr } = $dom

const rootSelector = '#intro';
const introSliderSelector = '.js-intro-slider';
const introSliderPaginationSelector = '.js-slider-pagination';
const introSliderButtonPrevSelector = '.js-slider-button-prev';
const introSliderButtonNextSelector = '.js-slider-button-next';

const introBackgroundImgSelector = '.js-intro-image';
const introSVGTriangleMainSelector = '.js-svg-trgl-main';
const introSVGTriangleSecondSelector = '.js-svg-trgl-scnd';
const introSVGTriangleSmallSelector = '.js-svg-trgl-small';

const bodyElement = get('body');
const animationDuration = 2;

export function introSlider() {

  bodyElement.style.setProperty('--slider-animation-duration', `${animationDuration}s`);

  const backgroundImages = getAll(introBackgroundImgSelector, rootSelector);
  const backgroundMainSvg = getAll(introSVGTriangleMainSelector, rootSelector);
  const backgroundScndSvg = getAll(introSVGTriangleSecondSelector, rootSelector);
  const backgroundSmallSvg = getAll(introSVGTriangleSmallSelector, rootSelector);

  const handleSlideChange = ({ slides, activeIndex }) => {
    const currentBackgroundImage = backgroundImages[activeIndex];
    const activeSlide = slides[activeIndex];

    if (backgroundMainSvg.length > 0 && Boolean(currentBackgroundImage)) {

      const rotate = attr(activeSlide, 'data-bg-rotate');
      const x = attr(activeSlide, 'data-bg-x');
      const y = attr(activeSlide, 'data-bg-y');

      removeClass(backgroundImages, 'active')
      addClass(currentBackgroundImage, 'active')

      if (Boolean(rotate)) {

        gsap.to(backgroundMainSvg, {
          rotate,
          transformOrigin: '50% 50%',
          x,
          y,
          duration: animationDuration,
        })
      }
    }

    if (backgroundScndSvg.length > 0 && Boolean(currentBackgroundImage)) {

      const rotate = attr(activeSlide, 'data-svg-scnd-rotate');
      const x = attr(activeSlide, 'data-svg-scnd-x');
      const y = attr(activeSlide, 'data-svg-scnd-y');
      const scale = attr(activeSlide, 'data-svg-scnd-scale');

      if (Boolean(rotate)) {

        gsap.to(backgroundScndSvg, {
          rotate,
          transformOrigin: '50% 50%',
          x,
          y,
          scale,
          duration: animationDuration,
        })
      }
    }

    if (backgroundSmallSvg.length > 0 && Boolean(currentBackgroundImage)) {

      const rotate = attr(activeSlide, 'data-svg-small-rotate');
      const x = attr(activeSlide, 'data-svg-small-x');
      const y = attr(activeSlide, 'data-svg-small-y');
      const scale = attr(activeSlide, 'data-svg-small-scale');

      if (Boolean(rotate)) {

        gsap.to(backgroundSmallSvg, {
          rotate,
          transformOrigin: '50% 50%',
          x,
          y,
          scale,
          duration: animationDuration,
        })
      }
    }
  }

  return new Component({
    name: 'introSlider',
    requiredTargets: rootSelector,

    onCreate() {
      
    },
    onInit() {

      const sliderOptions = {
        modules: [Navigation, Pagination, Autoplay, EffectCreative],
        direction: 'horizontal',

        autoplay: {
          delay: animationDuration * 1000,
        },
        speed: animationDuration * 1000,
        effect: 'creative',
        creativeEffect: {

          prev: {
            translate: ['-100%', 0, 0]
          },

          next: {
            translate: ['-100%', 0, 0]
          }
        },

        navigation: {
          prevEl: introSliderButtonPrevSelector,
          nextEl: introSliderButtonNextSelector,
        },

        pagination: {
          el: introSliderPaginationSelector,
          type: 'bullets',
          clickable: true,
          bulletClass: 'slider__pagination-bullet',
          bulletActiveClass: 'slider__pagination-bullet--active',
        },

        on: {
          init: handleSlideChange
        }

      }

      this.swiper = new Swiper(introSliderSelector, sliderOptions);

      this.swiper.on('slideChange', handleSlideChange)
    },
    onDestroy() {
      this.swiper = null;
    }
  })
}