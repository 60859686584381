import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import { $style } from '../../helpers/style';
import { gsap } from 'gsap';

const { get, attr } = $dom

const rootSelector = '#interaction';

const interactionContentSelector = '.js-interaction-content';
const interactionPartSelector = '.js-interaction-part';

const interactionDescSelector = '.js-interaction-desc';
const interactionDescBgSelector = '.js-interaction-desc-bg';
const interactionDescBgBlockSelector = '.interaction__info-img';
const interactionDescTextSelector = '.js-interaction-desc-text';
const interactionDescCloseButtonSelector = '.js-interaction-desc-close-button';

const interactionTriangleBig = '.js-outside-trng-big';
const interactionTriangleSmall = '.js-outside-trng-small';

const duration = .5;

export function interaction() {

  const timeline = gsap.timeline({})
  
  timeline.to(interactionContentSelector, {
    zIndex: 1,
    opacity: 0,
    duration,
  });
  timeline.to(interactionContentSelector, {
    zIndex: -1,
    rotate: -45,
    duration,
  });
  timeline.to(interactionDescSelector, {
    zIndex: 1,
    duration,
    rotate: 0,
  }, "<");
  timeline.to(interactionTriangleBig, {
    duration,
    rotate: 90,
  }, "<");
  timeline.to(interactionTriangleSmall, {
    duration,
    rotate: -45,
  }, "<");
  timeline.to(interactionDescBgBlockSelector, {
    duration,
    scale: 1,
    rotate: 0,
  }, "<");
  timeline.to(interactionDescTextSelector, {
    opacity: 1,
    duration,
  });
  timeline.to(interactionDescCloseButtonSelector, {
    opacity: 1,
    duration,
  }, "<");

  timeline.pause();

  const partClickHandler = target => {

    if (target.closest(interactionPartSelector)) {

      const background = attr(target, 'data-lazy-bg');

      get(interactionDescBgSelector).setAttribute('data-lazy-bg', background)
      $style.set(interactionDescBgSelector, 'background-image', `url("${background}")`)

      timeline.play();
    }
  }

  return new Component({
    name: 'interaction',
    requiredTargets: rootSelector,
    onCreate() { },
    onInit() {

      $events.delegate
        .on('click', interactionPartSelector, event => partClickHandler(event.target))
        .on('click', interactionDescCloseButtonSelector, () => timeline.reverse())
    },
    onDestroy() { 

      $events.delegate
        .off('click', interactionPartSelector, event => partClickHandler(event.target))
        .off('click', interactionDescCloseButtonSelector, () => timeline.reverse())

      timeline.kill();
    }
  })
}