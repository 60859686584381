import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import Component from '../../classes/Component';
import variables from '../../variables';

const { lg, md, sm } = variables.breakpoints;

const rootSelector = '#partner';
const introSliderSelector = '.js-partner-slider';
const introSliderPaginationSelector = '.js-slider-pagination';
const introSliderButtonPrevSelector = '.js-slider-button-prev';
const introSliderButtonNextSelector = '.js-slider-button-next';

export function partnerSlider() {
  return new Component({
    name: 'partnerSlider',
    requiredTargets: rootSelector,
    onCreate() { },
    onInit() {
      const sliderOptions = {
        modules: [Navigation, Pagination, Autoplay],
        direction: 'horizontal',

        speed: 2000,
        spaceBetween: 20,

        navigation: {
          prevEl: introSliderButtonPrevSelector,
          nextEl: introSliderButtonNextSelector,
        },

        pagination: {
          el: introSliderPaginationSelector,
          type: 'bullets',
          clickable: true,
          bulletClass: 'slider__pagination-bullet',
          bulletActiveClass: 'slider__pagination-bullet--active',
        },
        
        breakpoints: {
          [lg]: {
            slidesPerView: 4,
          },
          [md]: {
            slidesPerView: 2,
          },
          [sm]: {
            slidesPerView: 1,
          },
        }
      }

      this.swiper = new Swiper(introSliderSelector, sliderOptions);
    },
    onDestroy() {
      this.swiper = null
    }
  })
}