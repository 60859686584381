import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';

const { get, attr, createElement, append, addClass } = $dom

const modalRoot = '#js-video-modal';
const rootSelector = '.js-video-modal-root';

export default function modalVideo() {
  
  const modalVideoId = attr(rootSelector, 'data-bs-video-id')

  let player = null;

  function appendApi() {
    if (typeof (window.YT) == 'undefined' || typeof (window.YT.Player) == 'undefined') {
      const tag = document.createElement('script');

      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  window.onYouTubePlayerAPIReady = function () {
    player = new window.YT.Player('modal-video-player', {
      videoId: modalVideoId,
      rel: 0,
      controls: 0,
      events: {
        'onReady': addClass(rootSelector, 'is-loaded'),
      }
    });

    player.onLoad
  }

  const handleModalOpen = ({ target }) => {
    if (target.closest(modalRoot)) {
      player.playVideo()
    }
  }

  const handleModalClose = ({ target }) => {
    if (target.closest(modalRoot)) {
      player.pauseVideo()
    }
  }

  return new Component({
    name: 'modalVideo',
    requiredTargets: rootSelector,
    onCreate() { },
    onInit() {
      appendApi();

      $events.delegate
        .on('shown.bs.modal', window, handleModalOpen)
        .on('hidden.bs.modal', window, handleModalClose)
    },
    onDestroy() {
      player = null;

      $events.delegate
        .off('shown.bs.modal', window, handleModalOpen)
        .off('hidden.bs.modal', window, handleModalClose)
    }
  })
}