import Page from '../../classes/Page';
import { interaction } from './interaction';

const productPage = new Page({
  onCreate() {
    console.log('productPage create')
  },
  onInit() {
    console.log('productPage init')

    this
      .addComponent(interaction)
  },
  onDestroy() {
    console.log('productPage destroy')
  },
  name: 'product',
  rootElementId: 'js-page-product'
});

export default productPage;