import Page from '../../classes/Page';
import { informationCounter } from './informationCounter';
import modalVideo from './modalVideo';

const aboutPage = new Page({
  onCreate() {
    console.log('aboutPage create')
  },
  onInit() {
    console.log('aboutPage init')

    this
      .addComponent(informationCounter)
      .addComponent(modalVideo)
  },
  onDestroy() {
    console.log('aboutPage destroy')
  },
  name: 'about',
  rootElementId: 'js-page-about'
});

export default aboutPage;