import Page from '../../classes/Page';
import { introSlider } from './introSlider';
import { partnerSlider } from './partnerSlider';

const homePage = new Page({
  onCreate() {
    console.log('homePage create')
  },
  onInit() {
    console.log('homePage init')

    this
      .addComponent(introSlider)
      .addComponent(partnerSlider)
  },
  onDestroy() {
    console.log('homePage destroy')
  },
  name: 'home',
  rootElementId: 'js-page-home'
});

export default homePage;

